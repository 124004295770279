body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header-page {
  padding-top: 2rem;
  height: auto;
  background: #ededed;
}

.text-content-article {
  font-size: 16px;
  text-align: justify;

  .list-status {
    margin: 1rem 0;
  }
}

.indent-content {
  margin-left: 1rem;
}
.indent-content:not(:last-child) {
  margin-bottom: 3rem;
}

.body-page {
  border: 1px solid #dbdbdb;
  padding: 2rem 4rem;
  border-radius: 1rem;
  margin: 1rem 0;

  @media (max-width: 500px) {
    padding: 2rem 1rem;
  }
}

.quita {
  color: #1dd15d;
  font-weight: bold;
}

.central-ajuda-left {
  text-align: center;

  .central-ajuda-left-logo {
    justify-content: center;
    width: auto;
    height: 150px;
    margin-top: 5rem;
  }
}

.central-ajuda-right,
.search-result {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.subtitulo {
  font-size: 1rem;
  color: #a19d9d;
}
